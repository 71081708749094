"use client";

/**
 * Third-party libraries.
 */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/**
 * Project components.
 */
import { applicationConfiguration } from "@/application-configuration";
import { useAuthenticationContext } from "@/components/client/authentication";
import { CallUtility } from "@/components/client/call/utilities";
import {
  CommunicationDirection,
  CommunicationLogStatus,
} from "@/components/client/communication-log";
import {
  CommunicationLog,
  CommunicationLogGroup,
  CommunicationLogsGroupedByDate,
} from "@/components/client/communication-log/types";
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import { useApplicationContext } from "@/components/client/context";
import {
  CallDirection,
  CallsConcludedQuery,
  CallsConcludedQueryVariables,
  CallStatus,
  CallsUserActiveQuery,
  ConcludedCallsFilterInput,
  InputMaybe,
  useCallAcceptMutation,
  useCallCancelMutation,
  useCallEventSubscription,
  useCallLazyQuery,
  useCallRejectMutation,
  useCallsConcludedQuery,
  useCallsMissedQuery,
  useCallsUserActiveQuery,
} from "@/components/client/graphql";
import { useNotificationContext } from "@/components/client/notification";
import { useTwilioContext } from "@/components/client/twilio";

/**
 * Argument tha contains the call SID.
 */
type CallSidArgs = {
  /**
   * Twilio call SID of the call to check.
   */
  callSid: string;
};

/**
 * Communication Log context.
 *
 * This provides context for the communication logs such as:
 * - Active communication logs.
 * - Concluded communication logs.
 * - Selected communication log.
 *
 * This also provides functions for interacting with the communication log.
 */
type CommunicationLogContext = {
  // ===========================================================================
  // Call Actions
  // ===========================================================================
  /**
   * Accept an incoming call.
   */
  accept: (args: { callSid: string }) => Promise<void>;
  /**
   * Cancel, end, or reject a call.
   *
   * - Cancel
   *    - Outbound call that is ringing.
   * - Reject
   *    - Inbound call that is ringing.
   * - End
   *    - Ongoing inbound or outbound call.
   */
  hangUp: (
    args: CallSidArgs & {
      /**
       * The direction of the call direction.
       */
      direction: CallDirection;
      /**
       * The status of the call.
       */
      status: CallStatus;
    },
  ) => Promise<void>;
  /**
   * Check if the call is being accepted.
   */
  isAccepting: (args: CallSidArgs) => boolean;
  /**
   * Check if the call is being canceled, ended, or rejected.
   */
  isHangingUp: (args: CallSidArgs) => boolean;
  // ===========================================================================
  // Communication Logs Active
  // ===========================================================================
  /**
   * Active communication logs.
   *
   * These are logs which are currently being handled by the agent.
   */
  communicationLogsActive: CommunicationLogsGroupedByDate;
  /**
   * Indicates that the active communication logs are loading.
   */
  communicationLogsActiveLoading: boolean;
  /**
   * Indicates that the active communication logs are being refetched.
   */
  communicationLogsActiveFetchingMore: boolean;
  /**
   * Fetches more active communication logs.
   */
  fetchMoreCommunicationLogsActive: () => Promise<void>;
  /**
   * Indicates that there are more active communication logs to fetch.
   */
  hasMoreCommunicationLogsActive: boolean;
  // ===========================================================================
  // Communication Logs Concluded
  // ===========================================================================
  /**
   * Communication logs that have been concluded.
   *
   * These are logs which have already marked as concluded.
   * This includes canceled, completed, failed, missed, and rejected calls.
   */
  communicationLogsConcluded: CommunicationLogsGroupedByDate;
  /**
   * Indicates that the communication logs are loading.
   */
  communicationLogsConcludedLoading: boolean;
  /**
   * Indicates that the communication logs are being refetched.
   */
  communicationLogsConcludedFetchingMore: boolean;
  /**
   * Fetches more communication logs.
   */
  fetchMoreCommunicationLogsConcluded: () => Promise<void>;
  /**
   * Indicates that there are more communication logs to fetch.
   */
  hasMoreCommunicationLogsConcluded: boolean;
  /**
   * Sets the filter for the concluded calls.
   */
  setCommunicationLogsConcludedFilter: React.Dispatch<
    React.SetStateAction<InputMaybe<ConcludedCallsFilterInput> | undefined>
  >;

  // =====================================================================
  // Calls Missed
  // =====================================================================
  /**
   * Date groups of customer missed calls.
   *
   * These are missed calls of customers which have been grouped by date.
   */
  communicationLogsMissed: CommunicationLogGroup[];
  /**
   * Indicates that the calls missed query is still loading.
   */
  communicationLogsMissedLoading: boolean;
  /**
   * Indicates that the calls missed query is being refetched.
   */
  communicationLogsMissedFetchingMore: boolean;
  /**
   * Fetches more calls missed.
   */
  fetchMoreCommunicationLogsMissed: () => Promise<void>;
  /**
   * Indicates that there are more calls missed to fetch.
   */
  hasMoreCommunicationLogsMissed: boolean;

  // =====================================================================
  // Selected Communication Log
  // ===========================================================================
  /**
   * Selected communication log.
   * This is the currently active communication log from the communication log list.
   */
  selectedCommunicationLog: CommunicationLog | null;
  /**
   * Indicates that the selected communication log is loading.
   */
  selectedCommunicationLogLoading: boolean;
  /**
   * Sets the selected communication log.
   * This causes the other panels to display corresponding information in respect
   * of the active communication log record.
   */
  setSelectedCommunicationLog: (
    communicationLog: CommunicationLog | null,
  ) => void;
};

/**
 * Statuses of a call that is considered active.
 */
const CALL_STATUSES_ACTIVE = [
  CallStatus.InProgress,
  CallStatus.Queued,
  CallStatus.WrappingUp,
];

/**
 * Statuses of a call that are considered concluded.
 */
const CALL_STATUSES_CONCLUDED = [
  CallStatus.Canceled,
  CallStatus.Completed,
  CallStatus.Failed,
  CallStatus.Missed,
  CallStatus.Rejected,
];

/**
 * Communication Log related context.
 */
const CommunicationLogContext = React.createContext<CommunicationLogContext>({
  // ===========================================================================
  // Call Actions
  // ===========================================================================
  accept: async () => {},
  hangUp: async () => {},
  isAccepting: () => false,
  isHangingUp: () => false,
  // ===========================================================================
  // Communication Log Active
  // ===========================================================================
  communicationLogsActive: [],
  communicationLogsActiveLoading: true,
  communicationLogsActiveFetchingMore: false,
  fetchMoreCommunicationLogsActive: async () => {},
  hasMoreCommunicationLogsActive: true,
  // ===========================================================================
  // Communication Log Concluded
  // ===========================================================================
  communicationLogsConcluded: [],
  communicationLogsConcludedLoading: true,
  communicationLogsConcludedFetchingMore: false,
  fetchMoreCommunicationLogsConcluded: async () => {},
  hasMoreCommunicationLogsConcluded: true,
  setCommunicationLogsConcludedFilter: () => {},
  // ===========================================================================
  // Calls Missed
  // ===========================================================================
  communicationLogsMissed: [],
  communicationLogsMissedLoading: true,
  communicationLogsMissedFetchingMore: false,
  fetchMoreCommunicationLogsMissed: async () => {},
  hasMoreCommunicationLogsMissed: true,
  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================
  selectedCommunicationLog: null,
  selectedCommunicationLogLoading: true,
  setSelectedCommunicationLog: () => {},
});

/**
 * Use Communication Log context.
 */
export const useCommunicationLogContext = () => {
  return React.useContext(CommunicationLogContext);
};

/**
 * Communication log context provider.
 */
export const CommunicationLogContextProvider = ({
  children,
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Application context.
   */
  const { mockData } = useApplicationContext();

  const { user } = useAuthenticationContext();

  const { notification } = useNotificationContext();

  const { getCall: getTwilioCall } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [_acceptCall] = useCallAcceptMutation();
  const [_rejectCall] = useCallRejectMutation();
  const [_cancelCall] = useCallCancelMutation();

  /**
   * Active calls query.
   * Active calls are calls that are in the following statuses:
   * - Queued (Assigned to the current user)
   * - In Progress
   * - Wrapping Up
   */
  const {
    data: callsUserActiveData,
    networkStatus: callsActiveNetworkStatus,
    updateQuery: updateCallsUserActiveQuery,
  } = useCallsUserActiveQuery({
    variables: {
      limit: applicationConfiguration.pagination.limit,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  /**
   * Concluded calls query.
   * This fetches the concluded calls in the system.
   *
   * Concluded calls are calls that are in final statuses listed as follows:
   * - Canceled
   * - Completed
   * - Failed
   * - Missed
   * - Rejected
   */
  const {
    data: callsConcludedData,
    fetchMore: fetchMoreCallsConcluded,
    loading: loadingCallsConcluded,
    networkStatus: callsConcludedNetworkStatus,
    refetch: refetchCallsConcluded,
    updateQuery: updateCallsConcludedQuery,
  } = useCallsConcludedQuery({
    variables: {
      limit: applicationConfiguration.pagination.limit,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  /** Calls missed query. */
  const {
    data: callsMissedData,
    fetchMore: fetchMoreCommunicationLogsMissed,
    loading: loadingCommunicationLogsMissed,
    networkStatus: communicationLogsMissedNetworkStatus,
    refetch: refetchCallsMissedQuery,
  } = useCallsMissedQuery({
    variables: {
      limit: applicationConfiguration.pagination.limit,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  /**
   * Retrieves the details of a single call.
   */
  const [getCall] = useCallLazyQuery();

  /**
   * Subscribe to call events.
   */
  useCallEventSubscription({
    variables: {
      input: {
        userId: user?.id,
      },
    },
    onComplete() {
      console.log("useCallEventSubscription onComplete");
    },
    onError(error) {
      console.error("Error subscribing to call event", error);
    },
    /**
     * Updates the active and concluded calls when there is a call event.
     */
    onData({ client, data }) {
      const newCall = data.data?.callEvent;

      if (!newCall) return;

      /**
       * Update the active calls data when there is a call event.
       */
      updateCallsUserActiveQuery((previousData) => {
        if (!data.data) return previousData;

        const newCall = data.data.callEvent;

        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!newCall) return previousData;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the ublished event.
         */
        const existingCallIndex = previousData.callsUserActive.items?.findIndex(
          (call) => call.id === newCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * Indicates that the user can view the call.
         */
        const isCallUserViewable = CallUtility.isUserViewable({
          call: {
            status: newCall.status,
            userId: newCall.user?.id,
          },
          user: user!,
        });

        /**
         * This will hold the updated list of agent calls.
         */
        const updatedCalls = [...previousData.callsUserActive.items];

        /**
         * A generated key used for tagging a notification with a unique identifier.
         */
        const notificationTag = `notification-call-${newCall.id}`;

        // Call is viewable by the user.
        if (
          isCallUserViewable &&
          CALL_STATUSES_ACTIVE.includes(newCall.status)
        ) {
          // Update the existing call since it already exists in the list.
          if (isCallExisting) {
            updatedCalls[existingCallIndex] = newCall;
          }
          // Add the new call on top of the list since it doesn't exist.
          else {
            updatedCalls.unshift(newCall);

            // Show a desktop notification for incoming calls.
            if (newCall.direction === CallDirection.Inbound) {
              notification.desktop({
                tag: notificationTag,
                message: `Incoming call from ${newCall.from}`,
                title: `Incoming Call`,
              });
            }
          }

          if (
            // Auto select a new call in the active call section.
            !isCallExisting ||
            /**
             * Update the selected communication log if it is the same call.
             * This is to ensure the call details are up to date.
             */
            selectedCommunicationLog?.id === newCall.id
          ) {
            _setSelectedCommunicationLog(
              CommunicationLogUtility.fromCall({
                call: newCall,
              }),
            );

            /**
             * Remove the notification if the call is no longer ringing.
             */
            if (newCall.status !== CallStatus.Queued) {
              // Remove the notification.
              notification.close({
                tag: notificationTag,
              });
            }
          }
        }
        // Call is not viewable by the user.
        else if (isCallExisting) {
          // Remove the call from the list.
          updatedCalls.splice(existingCallIndex, 1);

          // Close the notification if it is removed.
          notification.close({
            tag: notificationTag,
          });
        }

        // Sort updatedCallsAgent from newest to oldest date.
        updatedCalls.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        return Object.assign({} as CallsUserActiveQuery, previousData, {
          callsUserActive: {
            ...previousData.callsUserActive,
            items: updatedCalls,
          },
        });
      });

      /**
       * Update the concluded calls data when there is a call event.
       */
      updateCallsConcludedQuery((previousData) => {
        if (!data.data) return previousData;

        const newCall = data.data.callEvent;

        /**
         * Nothing to do if there is no call in the published event.
         */
        if (!newCall) return previousData;

        /**
         * Index of the existing call in the calls agent list matching the
         * call in the published event.
         */
        const existingCallIndex = previousData.callsConcluded.items?.findIndex(
          (call) => call.id === newCall.id,
        );

        /**
         * Indicates whether the call exists in the existing calls agent list.
         */
        const isCallExisting = existingCallIndex > -1;

        /**
         * Indicates that the user can view the call.
         */
        const isCallUserViewable = CallUtility.isUserViewable({
          call: {
            status: newCall.status,
            userId: newCall.user?.id,
          },
          user: user!,
        });

        /**
         * This will hold the updated list of agent calls.
         */
        const updatedCallsAgent = [...previousData.callsConcluded.items];

        // Call is viewable by the user.
        if (
          isCallUserViewable &&
          CALL_STATUSES_CONCLUDED.includes(newCall.status)
        ) {
          // Update the existing call since it already exists in the list.
          if (isCallExisting) {
            updatedCallsAgent[existingCallIndex] = newCall;
          }
          // Add the new call on top of the list since it doesn't exist.
          else {
            updatedCallsAgent.unshift(newCall);
          }

          // Also update the selected communication log if it is the same call.
          if (selectedCommunicationLog?.id === newCall.id) {
            _setSelectedCommunicationLog(
              CommunicationLogUtility.fromCall({
                call: newCall,
              }),
            );
          }
        }
        // Call is not viewable by the user.
        else if (isCallExisting) {
          // Remove the call from the list.
          updatedCallsAgent.splice(existingCallIndex, 1);
        }

        // Sort updatedCallsAgent from newest to oldest date.
        updatedCallsAgent.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });

        return Object.assign({} as CallsConcludedQuery, previousData, {
          callsConcluded: {
            ...previousData.callsConcluded,
            items: updatedCallsAgent,
          },
        });
      });

      /** This is for refetching the calls missed query after a new call has been added. */
      refetchCallsMissedQuery()
        .then(() => {
          console.log("refetchCallsMissedQuery completed");
        })
        .catch((err) => console.error("refetchCallsMissedQuery error", err));
    },
  });

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // Call Actions
  // ===========================================================================

  /**
   * Call SIDs that are being accepted.
   */
  const [callSidsAccepting, setCallSidsAccepting] = useState<string[]>([]);

  /**
   * Call SIDs that are being canceled or rejected.
   */
  const [callSidsHangingUp, setCallSidsHangingUp] = useState<string[]>([]);

  // ===========================================================================
  // Communication Log Active
  // ===========================================================================
  const [
    communicationLogsConcludedFilter,
    setCommunicationLogsConcludedFilter,
  ] = useState<CallsConcludedQueryVariables["filter"]>();

  /**
   * Indicates that there are more communication logs to fetch.
   * The count excludes any ongoing calls.
   * The count for determining if there are more communication logs is based only
   * on the active calls (call status equal to queued, in progress, or wrapping
   * up that is assigned to the user).
   */
  const hasMoreCommunicationLogsActive = useMemo<
    CommunicationLogContext["hasMoreCommunicationLogsActive"]
  >(() => {
    // There are no active calls.
    if (
      !callsUserActiveData?.callsUserActive.totalCount ||
      !callsUserActiveData?.callsUserActive.items.length
    ) {
      return false;
    }

    /**
     * We are only counting the active calls for determining if there are
     * more communication logs.
     */
    return (
      (callsUserActiveData?.callsUserActive.totalCount || 0) >
      (callsUserActiveData?.callsUserActive.items.length || 0)
    );
  }, [callsUserActiveData]);

  // ===========================================================================
  // Communication Log Concluded
  // ===========================================================================

  /**
   * Indicates that there are more communication logs to fetch.
   * The count excludes any ongoing calls.
   * The count for determining if there are more communication logs is based only
   * on the concluded calls (call status not equal to pending, queued, in progress, or wrapping up).
   */
  const hasMoreCommunicationLogsConcluded = useMemo<
    CommunicationLogContext["hasMoreCommunicationLogsConcluded"]
  >(() => {
    // There are no concluded calls.
    if (
      !callsConcludedData?.callsConcluded.totalCount ||
      !callsConcludedData?.callsConcluded.items.length
    ) {
      return false;
    }

    /**
     * We are only counting the concluded calls for determining if there are
     * more communication logs.
     */
    return (
      (callsConcludedData?.callsConcluded.totalCount || 0) >
      (callsConcludedData?.callsConcluded.items.length || 0)
    );
  }, [callsConcludedData]);

  /**
   * Indicates that there are more missed calls to fetch.
   */
  const hasMoreCallsMissed = useMemo<boolean>(() => {
    // There are no more missed calls.
    if (
      !callsMissedData?.callsMissed.totalCount ||
      !callsMissedData?.callsMissed.items.length
    ) {
      return false;
    }

    /**
     * Compare the total number of missed calls with the current items count to determine
     * if there are more calls missed that needs to be fetched.
     */
    return (
      (callsMissedData?.callsMissed.totalCount || 0) >
      (callsMissedData?.callsMissed.items.length || 0)
    );
  }, [callsMissedData]);

  /**
   * The current offset for fetching more communication logs.
   */
  const [concludedCallsPaginationOffset, setConcludedCallsPaginationOffset] =
    useState<number>(applicationConfiguration.pagination.offset);

  const [missedCallsPaginationOffset, setMissedCallsPaginationOffset] =
    useState(applicationConfiguration.pagination.offset);

  // ===========================================================================
  // Selected Communication Log
  // ===========================================================================

  /**
   * Indicates which communication log is currently active on the screen.
   * This could control which subpanels are shown.
   */
  const [selectedCommunicationLog, setSelectedCommunicationLog] =
    useState<CommunicationLog | null>(null);

  /**
   * Indicates that the selected communication log is loading.
   */
  const [selectedCommunicationLogLoading, setSelectedCommunicationLogLoading] =
    useState<CommunicationLogContext["selectedCommunicationLogLoading"]>(true);

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  /**
   * The communication log records in the system.
   */
  const communicationLogsActive =
    useMemo<CommunicationLogsGroupedByDate>(() => {
      const groupLabel = "Active";

      const _communicationLogs: CommunicationLogsGroupedByDate = [
        {
          label: groupLabel,
          logs: [],
        },
      ];

      if (mockData) {
        _communicationLogs[0].logs.push({
          id: "1",
          agentName: "John Doe",
          call: {
            callSid: "CA12139sadf123947123423497234",
            direction: CallDirection.Inbound,
            id: "1",
            status: CallStatus.Queued,
          },
          callSid: "CA12139sadf123947123423497234",
          clientPhoneNumber: "Jane Doe",
          date: new Date(),
          direction: CommunicationDirection.INBOUND,
          from: "John Doe",
          status: CommunicationLogStatus.RINGING,
          time: "10:30 AM",
          to: "Jane Doe",
        });

        return _communicationLogs;
      }

      // There are no calls.
      if (!callsUserActiveData?.callsUserActive.items.length) {
        return _communicationLogs;
      }

      /**
       * Comminication logs grouped by date.
       * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
       */
      callsUserActiveData.callsUserActive.items.forEach((call) => {
        _communicationLogs[0].logs.push(
          CommunicationLogUtility.fromCall({
            call,
          }),
        );
      });

      return _communicationLogs;
    }, [callsUserActiveData, mockData]);

  /**
   * The communication log records in the system.
   */
  const communicationLogsConcluded =
    useMemo<CommunicationLogsGroupedByDate>(() => {
      if (mockData) {
        return [
          {
            label: "Today",
            logs: [
              {
                id: "5",
                agentName: "John Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "6",
                agentName: "Jake Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "7",
                agentName: "John Smith",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "8",
                agentName: "Alice Johnson",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
              {
                id: "9",
                agentName: "John Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "10",
                agentName: "Jake Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "11",
                agentName: "John Smith",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
            ],
          },
          {
            label: "Yesterday",
            logs: [
              {
                id: "12",
                agentName: "John Smith",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "13",
                agentName: "Alice Johnson",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
              {
                id: "14",
                agentName: "John Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "15",
                agentName: "Jake Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "16",
                agentName: "John Smith",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "17",
                agentName: "Alice Johnson",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
            ],
          },
          {
            label: "Two Days Ago",
            logs: [
              {
                id: "18",
                agentName: "John Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "19",
                agentName: "Jake Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
              {
                id: "20",
                agentName: "John Smith",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Smith",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "John Smith",
                status: CommunicationLogStatus.MISSED,
                time: "3:30 PM",
                to: "Jane Smith",
              },
              {
                id: "21",
                agentName: "Alice Johnson",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Bob Johnson",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "Alice Johnson",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Bob Johnson",
              },
              {
                id: "22",
                agentName: "John Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Missed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Jane Doe",
                date: new Date(),
                direction: CommunicationDirection.INBOUND,
                from: "John Doe",
                status: CommunicationLogStatus.MISSED,
                time: "10:30 AM",
                to: "Jane Doe",
              },
              {
                id: "23",
                agentName: "Jake Doe",
                call: {
                  callSid: "CA12139sadf123947123423497234",
                  direction: CallDirection.Inbound,
                  id: "5",
                  status: CallStatus.Completed,
                },
                callSid: "CA12139sadf123947123423497234",
                clientPhoneNumber: "Josh Doe",
                date: new Date(),
                direction: CommunicationDirection.OUTBOUND,
                from: "Jake Doe",
                status: CommunicationLogStatus.COMPLETED,
                time: "9:45 AM",
                to: "Josh Doe",
              },
            ],
          },
        ];
      }

      // There are no calls.
      if (!callsConcludedData?.callsConcluded.items.length) {
        return [
          {
            label: "Today",
            logs: [],
          },
        ];
      }

      /**
       * Comminication logs grouped by date.
       * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
       */
      const _communicationLog: CommunicationLogsGroupedByDate =
        CommunicationLogUtility.fromCallsGroupedByDate({
          calls: callsConcludedData.callsConcluded,
        });

      return _communicationLog;
    }, [callsConcludedData, mockData]);

  /**
   * The call missed records in the system.
   */
  const communicationLogsMissed = useMemo(() => {
    if (mockData) {
      return [{ label: "Today", logs: [] }];
    }

    // There are no calls.
    if (!callsMissedData?.callsMissed.items.length) {
      return [{ label: "Today", logs: [] }];
    }

    /**
     * Communication logs grouped by date.
     * This starts as empty and is being populated by the "addCommunicationLogToGroup" function.
     */
    const _communicationLog: CommunicationLogsGroupedByDate =
      CommunicationLogUtility.fromCallsGroupedByDate({
        calls: callsMissedData.callsMissed,
      });

    return _communicationLog;
  }, [mockData, callsMissedData]);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // Call Actions
  // ===========================================================================

  /**
   * Accept a call.
   */
  const acceptCall: CommunicationLogContext["accept"] = useCallback(
    async ({ callSid }) => {
      // Add the call sid to the list of currently being accepted calls.
      setCallSidsAccepting([...callSidsAccepting, callSid]);

      _acceptCall({
        variables: {
          input: {
            callSid,
          },
        },
      }).finally(() => {
        // Remove the call sid from the list of currently being accepted calls.
        setCallSidsAccepting(
          callSidsAccepting.filter((sid) => sid !== callSid),
        );
      });
    },
    [_acceptCall, callSidsAccepting],
  );

  /**
   * Cancel, end or reject a call.
   */
  const hangUpCall: CommunicationLogContext["hangUp"] = useCallback(
    async ({ callSid, direction, status }) => {
      // Add the call sid to the list of currently being canceled or rejected calls.
      setCallSidsHangingUp([...callSidsHangingUp, callSid]);

      switch (status) {
        case CallStatus.InProgress:
          const twilioCall = getTwilioCall({
            callSid,
          });

          if (!twilioCall) {
            throw new Error("Cannot hang up call. Call not found.");
          }

          twilioCall.disconnect();

          setTimeout(() => {
            setCallSidsHangingUp(
              callSidsHangingUp.filter((sid) => sid !== callSid),
            );
          }, 3000);
          break;
        case CallStatus.Queued:
          // Inbound ringing call.
          if (direction === CallDirection.Inbound) {
            _rejectCall({
              variables: {
                input: {
                  callSid,
                },
              },
            }).finally(() => {
              // Remove the call sid from the list of currently being canceled or rejected calls.
              setCallSidsHangingUp(
                callSidsHangingUp.filter((sid) => sid !== callSid),
              );
            });
            break;
          }
          // Outbound ringing call.
          else if (direction === CallDirection.Outbound) {
            _cancelCall({
              variables: {
                input: {
                  callSid,
                },
              },
            }).finally(() => {
              // Remove the call sid from the list of currently being canceled or rejected calls.
              setCallSidsHangingUp(
                callSidsHangingUp.filter((sid) => sid !== callSid),
              );
            });
          }
          break;
        default:
          break;
      }
    },
    [_cancelCall, _rejectCall, callSidsHangingUp, getTwilioCall],
  );

  /**
   * Check if the call is being accepted.
   */
  const isAccepting: CommunicationLogContext["isAccepting"] = useCallback(
    ({ callSid }) => callSidsAccepting.includes(callSid),
    [callSidsAccepting],
  );

  /**
   * Check if the call is being canceled, ended, or rejected.
   */
  const isHangingUp: CommunicationLogContext["isHangingUp"] = useCallback(
    ({ callSid }) => callSidsHangingUp.includes(callSid),
    [callSidsHangingUp],
  );

  // ===========================================================================
  // Communication Logs Concluded
  // ===========================================================================

  /**
   * Fetch more calls.
   */
  const _fetchMoreCallsConcluded = useCallback(async () => {
    // Do nothing if the communication logs are loading.
    if (loadingCallsConcluded) {
      return;
    }

    // Fetch more calls when the communication logs limit changes.
    fetchMoreCallsConcluded({
      variables: {
        filter: communicationLogsConcludedFilter,
        // Use the current call size as the next offset for fetching more calls.
        offset:
          concludedCallsPaginationOffset +
          applicationConfiguration.pagination.limit,
      },
      updateQuery(previousData, { fetchMoreResult }) {
        /**
         * A list of the calls viewable by the agent.
         * Fetch more results are added or updated in this list.
         */
        const updatedCallsAgent = [...previousData.callsConcluded.items];

        /**
         * IDs of the existing calls in the list. Initialize this with the
         * current existing call IDs.
         */
        const existingCallIds =
          previousData?.callsConcluded?.items.map((call) => call.id) ?? [];

        /**
         * Update the existing calls with the new calls or add the new call if
         * it doesn't exist in the existing calls.
         */
        fetchMoreResult.callsConcluded.items.forEach((newCall) => {
          /**
           * Index of the existing call in the calls agent list matching the
           * call in the fetch more result.
           *
           * @example
           * // A -1 value when it doesn't have a match
           * -1
           *
           * @example
           * // Greater than -1 value when it has a match
           * 0
           */
          const existingCallIndex = existingCallIds.indexOf(newCall.id);

          /**
           * Indicates whether the call exists in the existing calls agent list.
           */
          const existingCall = existingCallIndex > -1;

          /**
           * Indicates that the user can view the call.
           */
          const isCallUserViewable = CallUtility.isUserViewable({
            call: {
              status: newCall.status,
              userId: newCall.user?.id,
            },
            user: user!,
          });

          // Call is viewable by the user.
          if (isCallUserViewable) {
            // Call is existing in the call list.
            if (existingCall) {
              // Replace the existing call with the new call.
              updatedCallsAgent[existingCallIndex] = newCall;
            }
            // Call is not existing in the call list.
            else {
              // Add the call in the existing call list.
              existingCallIds.push(newCall.id);

              // Add the call in the updated call list.
              updatedCallsAgent.push(newCall);
            }
          }
          // Call is not viewable by the user.
          else {
            // Remove the call from the list.
            existingCallIds.splice(existingCallIndex, 1);

            // Remove the call from the list.
            updatedCallsAgent.splice(existingCallIndex, 1);
          }
        });

        if (
          fetchMoreResult.callsConcluded.offset <
          fetchMoreResult.callsConcluded.totalCount
        ) {
          /**
           * Keep track of the current offset for the next fetch more operation.
           */
          setConcludedCallsPaginationOffset(
            fetchMoreResult.callsConcluded.offset,
          );
        }

        return Object.assign({}, previousData, {
          callsConcluded: {
            items: updatedCallsAgent,
            limit: fetchMoreResult.callsConcluded.limit,
            offset: fetchMoreResult.callsConcluded.offset,
            totalCount: fetchMoreResult.callsConcluded.totalCount,
          },
        });
      },
    });
  }, [
    communicationLogsConcludedFilter,
    fetchMoreCallsConcluded,
    loadingCallsConcluded,
    concludedCallsPaginationOffset,
    user,
  ]);

  /**
   * Fetch more missed calls.
   */
  const _fetchMoreCallsMissed = useCallback(async () => {
    // Do nothing if the communication logs are loading.
    if (loadingCommunicationLogsMissed) {
      return;
    }

    // Fetch more calls when the communication logs limit changes.
    fetchMoreCommunicationLogsMissed({
      variables: {
        // Use the current call size as the next offset for fetching more calls.
        offset:
          missedCallsPaginationOffset +
          applicationConfiguration.pagination.limit,
      },
      updateQuery(previousData, { fetchMoreResult }) {
        /**
         * A list of the calls viewable by the agent.
         * Fetch more results are added or updated in this list.
         */
        const updatedCallsAgent = [...previousData.callsMissed.items];

        /**
         * IDs of the existing calls in the list. Initialize this with the
         * current existing call IDs.
         */
        const existingCallIds =
          previousData?.callsMissed?.items.map((call) => call.id) ?? [];

        /**
         * Update the existing calls with the new calls or add the new call if
         * it doesn't exist in the existing calls.
         */
        fetchMoreResult.callsMissed.items.forEach((newCall) => {
          /**
           * Index of the existing call in the calls agent list matching the
           * call in the fetch more result.
           *
           * @example
           * // A -1 value when it doesn't have a match
           * -1
           *
           * @example
           * // Greater than -1 value when it has a match
           * 0
           */
          const existingCallIndex = existingCallIds.indexOf(newCall.id);

          /**
           * Indicates whether the call exists in the existing calls agent list.
           */
          const existingCall = existingCallIndex > -1;

          /**
           * Indicates that the user can view the call.
           */
          const isCallUserViewable = CallUtility.isUserViewable({
            call: {
              status: newCall.status,
              userId: newCall.user?.id,
            },
            user: user!,
          });

          // Call is viewable by the user.
          if (isCallUserViewable) {
            // Call is existing in the call list.
            if (existingCall) {
              // Replace the existing call with the new call.
              updatedCallsAgent[existingCallIndex] = newCall;
            }
            // Call is not existing in the call list.
            else {
              // Add the call in the existing call list.
              existingCallIds.push(newCall.id);

              // Add the call in the updated call list.
              updatedCallsAgent.push(newCall);
            }
          }
          // Call is not viewable by the user.
          else {
            // Remove the call from the list.
            existingCallIds.splice(existingCallIndex, 1);

            // Remove the call from the list.
            updatedCallsAgent.splice(existingCallIndex, 1);
          }
        });

        if (
          fetchMoreResult.callsMissed.offset <
          fetchMoreResult.callsMissed.totalCount
        ) {
          /**
           * Keep track of the current offset for the next fetch more operation.
           */
          setMissedCallsPaginationOffset(fetchMoreResult.callsMissed.offset);
        }

        return Object.assign({}, previousData, {
          callsMissed: {
            items: updatedCallsAgent,
            limit: fetchMoreResult.callsMissed.limit,
            offset: fetchMoreResult.callsMissed.offset,
            totalCount: fetchMoreResult.callsMissed.totalCount,
          },
        });
      },
    });
  }, [
    fetchMoreCommunicationLogsMissed,
    loadingCommunicationLogsMissed,
    missedCallsPaginationOffset,
    user,
  ]);

  /**
   * Sets the selected communication log.
   */
  const _setSelectedCommunicationLog: CommunicationLogContext["setSelectedCommunicationLog"] =
    useCallback(
      (communicationLog) => {
        setSelectedCommunicationLogLoading(true);

        if (!communicationLog) {
          setSelectedCommunicationLog(communicationLog);
          return;
        }

        getCall({
          variables: {
            filter: {
              id: communicationLog.id,
            },
          },
          onCompleted: (data) => {
            if (!data.call) {
              return;
            }

            setSelectedCommunicationLog(
              CommunicationLogUtility.fromCall({
                call: data.call,
              }),
            );

            setSelectedCommunicationLogLoading(false);
          },
          onError: (error) => {
            setSelectedCommunicationLogLoading(false);
          },
        });
      },
      [getCall],
    );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the selected communication log when the call list changes.
   * - Removes it if it is not included anymore on the updated list. This might
   * happen when the call is reassigned to a different agent.
   * - Update the status if the call is still in the list.
   */
  useEffect(() => {
    /**
     * Indicates whether the selected communication log exists in the communication logs.
     *
     * This is used to determine whether the selected communication log should be reset.
     *
     * A selected communication log might not be valid in the communication logs with status
     * queued because it might have been reassigned to a different agent.
     */
    let selectedCommunicationLogExists = false;

    for (const call of callsUserActiveData?.callsUserActive.items ?? []) {
      if (selectedCommunicationLog?.id !== call.id) {
        continue;
      }

      selectedCommunicationLogExists = true;

      break;
    }

    /**
     * Check if the existing selected communication log is still in the list after
     * a refetch.
     *
     * Reset the selected communication log so we can make sure the status
     * is up to date.
     */
    for (const call of callsConcludedData?.callsConcluded.items ?? []) {
      if (selectedCommunicationLogExists) {
        break;
      }

      if (selectedCommunicationLog?.id !== call.id) {
        continue;
      }

      selectedCommunicationLogExists = true;

      break;
    }

    /**
     * Clear the selected communication log so we can remove any panels
     * that are associated with the communication log if:
     * - There are no communication logs.
     * - The selected communication log doesn't exist anymore in the list. This
     * might happen in the case of a queued call that was reassigned to a different agent.
     */
    if (!selectedCommunicationLogExists) {
      _setSelectedCommunicationLog(null);
    }
  }, [
    callsConcludedData,
    selectedCommunicationLog?.id,
    _setSelectedCommunicationLog,
    callsUserActiveData,
  ]);

  /**
   * Refetch the concluded calls if the filter changed.
   *
   * This would update the concluded communication logs showing on the screen.
   */
  useEffect(() => {
    refetchCallsConcluded({
      filter: communicationLogsConcludedFilter,
      limit: applicationConfiguration.pagination.limit,
      offset: 0,
    });
  }, [communicationLogsConcludedFilter, refetchCallsConcluded]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <CommunicationLogContext.Provider
      value={{
        // =====================================================================
        // Call Actions
        // =====================================================================
        accept: acceptCall,
        hangUp: hangUpCall,
        isAccepting,
        isHangingUp,
        // =====================================================================
        // Communication Logs Active
        // =====================================================================
        communicationLogsActive,
        communicationLogsActiveFetchingMore: callsActiveNetworkStatus === 3,
        communicationLogsActiveLoading: callsActiveNetworkStatus === 1,
        fetchMoreCommunicationLogsActive: async () => {
          alert("TODO: fetchMoreCommunicationLogsActive not yet implemented.");
        },
        hasMoreCommunicationLogsActive,
        // =====================================================================
        // Communication Logs Concluded
        // =====================================================================
        communicationLogsConcluded,
        communicationLogsConcludedFetchingMore:
          callsConcludedNetworkStatus === 3,
        communicationLogsConcludedLoading: callsConcludedNetworkStatus === 1,
        fetchMoreCommunicationLogsConcluded: _fetchMoreCallsConcluded,
        hasMoreCommunicationLogsConcluded,
        setCommunicationLogsConcludedFilter,
        // =====================================================================
        // Calls Missed Groups
        // =====================================================================
        communicationLogsMissed: communicationLogsMissed,
        communicationLogsMissedLoading: loadingCommunicationLogsMissed,
        communicationLogsMissedFetchingMore:
          communicationLogsMissedNetworkStatus === 3,
        fetchMoreCommunicationLogsMissed: _fetchMoreCallsMissed,
        hasMoreCommunicationLogsMissed: hasMoreCallsMissed,

        // =====================================================================
        // Selected Communication Log
        // =====================================================================
        selectedCommunicationLog,
        selectedCommunicationLogLoading,
        setSelectedCommunicationLog: _setSelectedCommunicationLog,
      }}
    >
      {children}
    </CommunicationLogContext.Provider>
  );
};
