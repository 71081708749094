"use client";

/**
 * Third-party libraries.
 */
import { Button, ButtonProps } from "antd";
import { useMemo } from "react";

/**
 * Project components.
 */

import "./button-call.css";

/**
 * Types of buttons for call control.
 */
export enum ButtonCallType {
  /**
   * A green button with a white icon.
   */
  ACCEPT = "accept",
  /**
   * A red button with a white icon.
   */
  HANG_UP = "hang_up",
  /**
   * A default AntD icon icon.
   */
  MUTE = "mute",
}

/**
 * Call control properties.
 */
export type ButtonCallProps = {
  /**
   * Disables the button.
   */
  disabled?: ButtonProps["disabled"];
  /**
   * Icon to display on the button.
   */
  icon?: ButtonProps["icon"];
  /**
   * Disables the button and shows a loading spinner.
   */
  loading?: ButtonProps["loading"];
  /**
   * Callback when the button is clicked.
   */
  onClick?: ButtonProps["onClick"];
  /**
   * Type of button.
   *
   * Controls the color of the button and the icon.
   */
  type: ButtonCallType;
};

/**
 * Call control button.
 *
 * Can be one of the following types:
 * - Accept call
 * - Hang up call
 * - Mute call
 */
export function ButtonCall({
  disabled,
  icon,
  loading,
  onClick,
  type,
}: ButtonCallProps) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  const buttonClassName = useMemo<string>(() => {
    switch (type) {
      case ButtonCallType.ACCEPT:
        // This should be pressent in the ./button-call.css file.
        return "button-call-accept";
      case ButtonCallType.HANG_UP:
        return "button-call-hang-up";
      case ButtonCallType.MUTE:
        return "button-call-mute";
      default:
        throw new Error(`Unknown button call type: ${type}`);
    }
  }, [type]);

  return (
    <Button
      className={buttonClassName}
      disabled={disabled}
      icon={icon}
      loading={loading}
      onClick={onClick}
      shape="circle"
      style={{
        height: 40,
        width: 40,
      }}
      type="primary"
    />
  );
}
