import { CopyOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { useNotificationContext } from "../notification";

/** Props for `CopyButton` component. */
export type CopyButtonProps = ButtonProps & {
  /** Value to copy. */
  value: string;

  /** Text to display. */
  text?: string;
};

/** Button Component for copying a value to the clipboard. */
export default function CopyButton(props: CopyButtonProps) {
  /** Used for sending notification. */
  const { notification } = useNotificationContext();

  /** Handler function for copying the contact details to the clipboard. */
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      notification.info({ message: "Copied to clipboard!" });
    } catch (err) {
      notification.error({ message: "Failed to copy to clipboard!" });
      console.error("Failed to copy to clipboard!", err);
    }
  };

  return (
    <Button
      type="link"
      className="!h-5 !py-0"
      icon={<CopyOutlined />}
      content={props.text}
      onClick={() => copyToClipboard(props.value)}
    />
  );
}
